function init() {
    if (typeof window === 'undefined') {
        return
    }
    import('./_animation').then(animation => {
        animation.init()
    })
}

export {
    init
}